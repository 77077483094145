import * as React from "react";

const CreditCard = (props) => (
  <svg
    width={18}
    height={16}
    viewBox={"0 0 18 16"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.667 6h14.667m-4.667 4.333h2M3 13.333h12c.737 0 1.334-.597 1.334-1.333V4c0-.737-.597-1.333-1.334-1.333H3c-.736 0-1.333.596-1.333 1.333v8c0 .736.597 1.333 1.333 1.333z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CreditCard;
