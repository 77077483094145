import * as React from "react";

const Bike = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox={"0 0 24 24"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={19}
      cy={16.5}
      r={4}
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m19 16.5-3.5-12 4-1"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={5}
      cy={16.5}
      r={4}
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 6.5h-4M9 7.5l3.5 9M5 16.5l11.5-7"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bike;
