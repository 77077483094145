import * as React from "react";

const Flag = (props) => (
  <svg
    width={20}
    height={20}
    viewBox={"0 0 20 20"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.333 12.501s.833-.833 3.333-.833 4.167 1.667 6.667 1.667 3.333-.834 3.333-.834v-10s-.833.834-3.333.834-4.167-1.667-6.667-1.667-3.333.833-3.333.833v10z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.333 18.333V12.5"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Flag;
