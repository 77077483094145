import * as React from "react";

const Logout = (props) => (
  <svg
    width={16}
    height={16}
    viewBox={"0 0 16 16"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.667 14.667H3.333A1.333 1.333 0 012 13.334V2.667c0-.736.597-1.333 1.333-1.333h3.334M11.333 10.667L14 8.001l-2.667-2.667"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 8H6"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Logout;
