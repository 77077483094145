import * as React from "react";

const WeatherCloudy = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox={"0 0 24 24"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.332 14.255v1.264m0-1.264h1.133c.596 0 1.186.108 1.736.32.55.212 1.05.523 1.47.914.422.392.756.857.984 1.368A3.96 3.96 0 0 1 21 18.47c0 2.328-2.032 2.53-4.535 2.53H8.303c-3.773 0-6.92-.995-7.256-4.486-.211-1.714.292-3.439 1.406-4.819 1.115-1.38 2.756-2.31 4.585-2.6a7.7 7.7 0 0 1 5.252 1.044c1.543.96 2.631 2.433 3.042 4.116ZM17.08 10.895a3.523 3.523 0 1 0-5.849-3.93M18.478 2.497l-.786 1.17M12.663 1.323l.272 1.388M7.723 4.607l1.17.786M19.418 12.466l1.17.786M20.376 7.706l1.382-.271"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WeatherCloudy;
