import * as React from "react";

const Parking = (props) => (
  <svg
    width={16}
    height={16}
    viewBox={"0 0 16 16"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.334 8.332h5.778c1.595 0 2.889-1.343 2.889-3s-1.294-3-2.89-3H4.335v6Zm0 0v5.333"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Parking;
