import * as React from "react";

const GuestCardTwo = (props) => (
  <svg
    width={36}
    height={36}
    viewBox={"0 0 36 36"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.917 5.959a4 4 0 014-4h24.666a4 4 0 014 4v24.083a4 4 0 01-4 4H5.917a4 4 0 01-4-4V5.96z"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinejoin="round"
    />
    <path
      d="M29.187 15.084h-7.291M29.187 20.918h-7.291"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx={12.417}
      cy={12.938}
      rx={3.19}
      ry={3.104}
      stroke="currentColor"
      strokeWidth={2.5}
    />
    <path
      d="M6.948 23.033a3 3 0 013-3H14.885a3 3 0 013 3v2.208a1 1 0 01-1 1H7.948a1 1 0 01-1-1v-2.208z"
      stroke="currentColor"
      strokeWidth={2.5}
    />
  </svg>
);

export default GuestCardTwo;
