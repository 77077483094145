import * as React from "react";

const Arrival = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_3590_162191)" stroke="currentColor">
      <path
        d="M8.17 18.608a.5.5 0 00.358.701l2.035.393a.5.5 0 00.472-.163L16 13.837a.5.5 0 01.472-.163l2.588.5c.05.01.098.016.149.02.52.041 3.616.22 3.907-1.286.29-1.506-2.65-2.492-3.148-2.647a1.337 1.337 0 00-.146-.036l-2.588-.5a.5.5 0 01-.378-.326L14.37 2.258a.5.5 0 00-.377-.327l-2.035-.392a.5.5 0 00-.594.517l.327 6.158a.5.5 0 01-.565.522L6.148 8.08a.5.5 0 01-.403-.32l-.833-2.218a.5.5 0 00-.374-.315L2.943 4.92a.5.5 0 00-.591.547l.373 3.356a.5.5 0 01-.056.292L1.073 12.09a.5.5 0 00.346.727l1.595.308a.5.5 0 00.464-.153l1.599-1.749a.5.5 0 01.493-.147l4.865 1.243a.5.5 0 01.33.695L8.17 18.608z"
        strokeWidth={1.8}
      />
      <path
        d="M22.5 22.5h-10"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_3590_162191">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Arrival;
