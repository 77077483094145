import * as React from "react";

const login = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 22h5a2 2 0 002-2V4a2 2 0 00-2-2h-5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 16l4-4-4-4M15 12H3"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default login;
