import * as React from "react";

const Lock = (props) => (
  <svg
    width={20}
    height={20}
    viewBox={"0 0 20 20"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 11.168a2 2 0 012-2h11a2 2 0 012 2v5.167a2 2 0 01-2 2h-11a2 2 0 01-2-2v-5.167z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.833 9.168V5.835a4.167 4.167 0 118.333 0v3.333"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Lock;
