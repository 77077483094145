import * as React from "react";

const WeatherRain = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 17.25v4M18 17.25v2M6 16.25v3M10 16.25v6M16.746 8.251h1.26a5 5 0 015 5c0 2.761-2.238 3-5 3h-9c-4.158.003-7.625-1.18-7.977-5.323a8 8 0 0115.717-2.677zm0 0V9.75"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WeatherRain;
