import * as React from "react";

const WeatherStorm = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox={"0 0 24 24"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.746 9.001h1.26a5 5 0 0 1 5 5c0 2.761-2.238 3-5 3m-1.26-8A8 8 0 0 0 1.03 11.678c.352 4.143 2.971 5.323 5.977 5.323m9.74-8V10.5M12.5 12.75l-2.5 4.5h5l-2.5 4.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WeatherStorm;
