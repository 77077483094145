import * as React from "react";

const ExitButton = (props) => (
  <svg
    width={24}
    height={25}
    viewBox={"0 0 24 25"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 6.5l-12 12M6 6.5l12 12"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExitButton;
