import * as React from "react";

const EyeOff = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.94 17.94A10.07 10.07 0 0112 20c-5.991 0-9.785-5.86-10.754-7.55a.902.902 0 01.005-.903A18.45 18.45 0 016.06 6.06M9.9 4.24A9.12 9.12 0 0112 4c5.992 0 9.786 5.863 10.754 7.55a.902.902 0 01-.004.903 18.501 18.501 0 01-1.91 2.737m-6.72-1.07a3 3 0 11-4.24-4.24M1 1l22 22"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeOff;
