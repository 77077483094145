import * as React from "react";

const Mailbox = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox={"0 0 24 24"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.539 9.126a.9.9 0 1 0 0 1.8v-1.8Zm5.076.9h.9a.9.9 0 0 0-.9-.9v.9Zm-.9 2.256a.9.9 0 0 0 1.8 0h-1.8Zm-12.3-8.45.058-.899-.058.898ZM2.692 20.18v.9-.9ZM5.92 9.75a.15.15 0 0 1 .15-.15v1.8a1.65 1.65 0 0 0 1.65-1.65h-1.8Zm.15-.15a.15.15 0 0 1 .15.15h-1.8c0 .911.739 1.65 1.65 1.65V9.6Zm.15.15a.15.15 0 0 1-.15.15V8.1a1.65 1.65 0 0 0-1.65 1.65h1.8Zm-.15.15a.15.15 0 0 1-.15-.15h1.8A1.65 1.65 0 0 0 6.07 8.1v1.8Zm8.469 1.026h5.076v-1.8H14.54v1.8Zm4.176-.9v2.256h1.8v-2.256h-1.8ZM6.077 4.72h11.846v-1.8H6.077v1.8Zm11.846 0A4.177 4.177 0 0 1 22.1 8.897h1.8a5.977 5.977 0 0 0-5.977-5.976v1.8ZM22.1 8.897v9.59h1.8v-9.59h-1.8Zm0 9.59a.792.792 0 0 1-.792.793v1.8a2.592 2.592 0 0 0 2.592-2.593h-1.8Zm-.792.793H11.154v1.8h10.154v-1.8ZM1.9 8.896c0-2.399 2.022-4.325 4.457-4.168l.116-1.796A5.978 5.978 0 0 0 .1 8.897h1.8ZM6.357 4.73c2.185.142 3.897 2.071 3.897 4.342h1.8c0-3.137-2.369-5.93-5.581-6.138L6.357 4.73Zm3.897 4.342V20.18h1.8V9.07h-1.8Zm.9 10.208H2.692v1.8h8.462v-1.8Zm-8.462 0a.792.792 0 0 1-.792-.792H.1a2.592 2.592 0 0 0 2.592 2.593v-1.8Zm-.792-.792v-9.59H.1v9.59h1.8Z"
      fill="currentColor"
    />
  </svg>
);

export default Mailbox;
