import * as React from "react";

const FullScreen = (props) => (
  <svg
    width={24}
    height={25}
    viewBox={"0 0 24 25"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 3.5h6v6M9 21.5H3v-6M21 3.5l-7 7M3 21.5l7-7"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FullScreen;
