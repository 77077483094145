import * as React from "react";

const Calendar = (props) => (
  <svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1.667}
      y={4.332}
      width={16.667}
      height={15.833}
      rx={3}
      stroke="currentColor"
      strokeWidth={1.8}
    />
    <path
      d="M5.834 1.832v1.667M14.167 1.832v1.667M1.667 8.5h16.667"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Calendar;
