import * as React from "react";

const BootSki = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1.894 12.916 2.681 2.212a2.463 2.463 0 0 0 3.518-.383l3.973-5.009M12.288 7.191H6.21M8.898 21.5H2.82M21.228 21.5h-6.079M13.3 13.232l-1.542 2.862M16.073 14.188l-.582 1.271M19 15.143l-.46.953"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.593 2.503c-.656-.045-1.237.433-1.339 1.103L1.346 16.072a3.955 3.955 0 0 0 .854 3.119 3.704 3.704 0 0 0 2.852 1.356h15.19c.796 0 1.538-.406 1.983-1.085.446-.678.536-1.54.24-2.302l-.116-.302a2.404 2.404 0 0 0-1.496-1.427l-6.893-2.222c-1.11-.358-1.84-1.455-1.762-2.652l.377-5.838c.06-.916-.612-1.709-1.5-1.77l-6.482-.446Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BootSki;
