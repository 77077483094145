import * as React from "react";

const CovidSecurity = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13 25s10-5.4 10-12V4.2L13 1 3 4.2V13c0 6.6 10 12 10 12Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M13 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 6v2.4M13 15.6V18M8.758 7.758l1.698 1.698M15.544 14.544l1.698 1.698M7 12h2.4M16.6 12H19M8.758 16.242l1.698-1.698M15.544 9.456l1.698-1.698"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CovidSecurity;
