import * as React from "react";

const Bed2 = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.205 7.428h3.382c1.869 0 3.383 1.535 3.383 3.429v6.857"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M22.5 17.714H2.205M22.5 12H2.205M1.5 3v17M22.5 8.571V20"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bed2;
