import * as React from "react";

const QRScanner = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.111 3H5.444A2.444 2.444 0 0 0 3 5.444v2.667m18 0V5.444A2.444 2.444 0 0 0 18.556 3h-2.667m0 18h2.667A2.444 2.444 0 0 0 21 18.556v-2.667m-18 0v2.667A2.444 2.444 0 0 0 5.444 21h2.667M23 12H1"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default QRScanner;
