import * as React from "react";

const Flights = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.932 6.96a.5.5 0 0 1-.105-.787l1.657-1.657a.5.5 0 0 1 .462-.135l7.564 1.681a.5.5 0 0 0 .462-.134l2.061-2.062c.04-.039.078-.075.121-.11.442-.36 3.102-2.442 4.18-1.363 1.08 1.08-1.003 3.739-1.362 4.18a1.587 1.587 0 0 1-.11.122L17.8 8.756a.5.5 0 0 0-.134.462l1.68 7.564a.5.5 0 0 1-.134.462L17.555 18.9a.5.5 0 0 1-.788-.105l-3.045-5.33a.5.5 0 0 0-.768-.123l-4.136 3.71a.5.5 0 0 0-.157.468l.473 2.417a.5.5 0 0 1-.137.45l-1.31 1.31a.5.5 0 0 1-.803-.133l-1.48-3.012a.5.5 0 0 0-.229-.229l-3.012-1.48a.5.5 0 0 1-.133-.803l1.31-1.31a.5.5 0 0 1 .45-.137l2.417.473a.5.5 0 0 0 .468-.157l3.71-4.136a.5.5 0 0 0-.124-.768L4.932 6.96Z"
      stroke="currentColor"
      strokeWidth={1.8}
    />
  </svg>
);

export default Flights;
