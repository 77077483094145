import * as React from "react";

const CloudRain = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 13V21"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 13V21"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15V23"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 16.58C22.1667 15.631 23.372 13.2936 22.8886 10.9782C22.4052 8.6627 20.3654 7.00277 18 6.99998H16.74C15.8696 3.62944 12.9195 1.20864 9.4439 1.01293C5.96829 0.817214 2.76507 2.89151 1.52177 6.14304C0.278473 9.39456 1.28053 13.0768 4.00003 15.25"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloudRain;
