import * as React from "react";

const Buildings = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <path
      d="M21.81 23V11.703c0-.657-.531-1.19-1.188-1.19h-2.973c-.657 0-1.19.533-1.19 1.19V23 7.54c0-.657-.532-1.189-1.189-1.189h-1.189V3.973L12 2.783M2.19 23V12.56c0-.512.327-.966.813-1.128l2.973-.991a1.189 1.189 0 0 1 1.565 1.128V23 7.54c0-.657.532-1.189 1.189-1.189h1.189V3.973L12 2.783m0 0V1m.595 8.622h-1.19m1.19 3.567h-1.19m1.19 3.568h-1.19m1.19 3.567h-1.19"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Buildings;
