import React from "react";
import * as styles from "./styles.module.scss";

interface ContainerProps {
  className?: string;
  children?: any;
  maxWidth?: Width;
  component?: string;
  isFakeContainer?: boolean;
  id?: string;
  style?: object;
}

type Width = false | "xs" | "sm" | "md" | "lg" | "xl";

const Container = React.forwardRef((props: ContainerProps, ref: any) => {
  const {
    className = "",
    children,
    maxWidth = false,
    component = "div",
    isFakeContainer = false,
    id,
    style,
  } = props;

  const CustomTag: any = `${component}`;

  const maxWidthStyle = maxWidth
    ? styles[`maxWidth${maxWidth.charAt(0).toUpperCase() + maxWidth.slice(1)}`]
    : "";

  const config = {};

  if (id) config.id = id;
  if (style) config.style = style;

  if (isFakeContainer) {
    return (
      <CustomTag
        className={`${styles.container} ${maxWidthStyle} ${styles.isFake} ${className}`}
        {...config}
      />
    );
  }

  return (
    <CustomTag
      className={`${styles.container} ${maxWidthStyle} ${className}`}
      ref={ref}
      {...config}
    >
      {children}
    </CustomTag>
  );
});

export default Container;
