import * as React from "react";

const WeatherHalfsunny = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.756 1.09v2.183M3.111 4.604L4.69 6.153M18.822 6.153l1.578-1.55M4.5 13h6.278M14.222 11.182h7.667M2 10h6M12 20.727v2.182M19.067 17.847l1.577 1.55M3.355 19.396l1.578-1.549"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.07 15.857a5.564 5.564 0 001.803 1.182 5.643 5.643 0 004.252 0 5.563 5.563 0 001.803-1.182 5.446 5.446 0 001.204-1.77M9.873 6.961a5.645 5.645 0 014.252 0 5.564 5.564 0 011.803 1.182"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
    />
  </svg>
);

export default WeatherHalfsunny;
