import * as React from "react";

const Upload = (props) => (
  <svg
    width={16}
    height={16}
    viewBox={"0 0 16 16"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 11.334v2c0 .736.597 1.333 1.333 1.333h9.334c.736 0 1.333-.597 1.333-1.333v-2M10.667 4L8 1.335 5.333 4.001M8 1.334v9.333"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Upload;
