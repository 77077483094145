import * as React from "react";

const Edit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M22 21.9H2L8.4 20l13-13c.4-.4 1.5-2.4-.3-4.1-1.9-1.8-3.9-.8-4.4-.2-.1.2-12.9 13.1-12.9 13.1L2 21.9M14.7 4.7l4.5 4.5"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
    />
  </svg>
);

export default Edit;
