import * as React from "react";

const Eye = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.237 12.434a.864.864 0 010-.868C2.191 9.898 5.99 4 12 4s9.809 5.898 10.763 7.566a.864.864 0 010 .868C21.809 14.102 18.01 20 12 20s-9.809-5.898-10.763-7.566z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M12 15a3 3 0 100-6 3 3 0 000 6z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Eye;
