import * as React from "react";

const WeatherSleet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M16.8 9.2v1.5m0-1.5H18c.7 0 1.3.1 1.9.4.6.3 1.2.6 1.6 1.1.5.5.8 1 1.1 1.6.3.6.4 1.3.4 1.9 0 2.8-2.2 3-5 3H9c-4.2 0-7.6-1.2-8-5.3-.2-2 .3-4.1 1.6-5.7 1.2-1.6 3-2.7 5.1-3.1 2-.3 4.1.1 5.8 1.2s2.8 2.9 3.3 4.9z"
      stroke={"currentColor"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
      fill="none"
    />
    <path
      d="m1 20.5 3.8-3.8M9 20.5l3.2-3.2M5 20.5l3.2-3.2"
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      fill="none"
    />
    <circle
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      cx={14.6}
      cy={18.4}
      r={0.5}
    />
    <circle
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      cx={17.6}
      cy={18.4}
      r={0.5}
    />
    <circle
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      cx={20.6}
      cy={18.4}
      r={0.5}
    />
    <circle
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      cx={12.7}
      cy={20.6}
      r={0.5}
    />
    <circle
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      cx={15.7}
      cy={20.6}
      r={0.5}
    />
    <circle
      stroke={"currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      cx={18.6}
      cy={20.6}
      r={0.5}
    />
  </svg>
);

export default WeatherSleet;
