import React, { lazy, Suspense } from "react";
import { IconProps } from "./types";
import * as styles from "./styles.module.scss";

// Fill
import Mailbox from "./mailbox";

// Stroke
import AlertCircle from "./alertCircle";
import AlertTriangle from "./alertTriangle";
import Arrival from "./arrival";
import ArrowDown from "./arrowDown";
import ArrowDownLeft from "./arrowDownLeft";
import ArrowDownRight from "./arrowDownRight";
import ArrowLeft from "./arrowLeft";
import ArrowRight from "./arrowRight";
import ArrowUp from "./arrowUp";
import ArrowUpLeft from "./arrowUpLeft";
import ArrowUpRight from "./arrowUpRight";
import Bed2 from "./bed2";
import Beds from "./beds";
import Bell2 from "./bell2";
import Bike from "./bike";
import BoardingPass from "./boardingPass";
import BootSki from "./bootSki";
import Buildings from "./buildings";
import Calendar from "./calendar";
import Carpet from "./carpet";
import Center from "./center";
import Chairlift from "./chairlift";
import Check from "./check";
import CheckCircle from "./checkCircle";
import ChevronDown from "./chevronDown";
import ChevronLeft from "./chevronLeft";
import ChevronRight from "./chevronRight";
import ChevronsDown from "./chevronsDown";
import ChevronsUp from "./chevronsUp";
import ChevronUp from "./chevronUp";
import Clock from "./clock";
import Cloud from "./cloud";
import CloudRain from "./cloudRain";
import Compass from "./compass";
import CovidSecurity from "./covidSecurity";
import CreditCard from "./creditCard";
import Cross from "./cross";
import DownloadButton from "./downloadButton";
import DragHorizontal from "./dragHorizontal";
import Edit from "./edit";
import Email from "./email";
import ExitButton from "./exitButton";
import Eye from "./eye";
import EyeOff from "./eyeOff";
import File from "./file";
import FileText from "./fileText";
import Filter from "./filter";
import Flag from "./flag";
import Flights from "./flights";
import FullScreen from "./fullScreen";
import Gondola from "./gondola";
import Grid from "./grid";
import GuestCard from "./guestCard";
import GuestCardTwo from "./guestCardTwo";
import Helmet from "./helmet";
import Image from "./image";
import Info from "./info";
import Lift from "./lift";
import List from "./list";
import Lock from "./lock";
import Login from "./login";
import Logout from "./logout";
import MapPin from "./mapPin";
import Menu from "./menu";
import Minus from "./minus";
import MinusCircle from "./minusCircle";
import Package from "./package";
import Parking from "./parking";
import Phone from "./phone";
import Pin from "./pin";
import PlayButton from "./playButton";
import Plus from "./plus";
import PlusCircle from "./plusCircle";
import Poles from "./poles";
import QrScanner from "./qrScanner";
import Search from "./search";
import Settings from "./settings";
import ShoppingCart from "./shoppingCart";
import Skiing from "./skiing";
import SkisOnly from "./skisOnly";
import Snow from "./snow";
import Snowboard from "./snowboard";
import Snowflake from "./snowflake";
import Sort from "./sort";
import Star from "./star";
import Sun from "./sun";
import SunMenu from "./sunMenu";
import Toaster from "./toaster";
import Tow from "./tow";
import Town from "./town";
import TrailBlack from "./trailBlack";
import TrailBlack2 from "./trailBlack2";
import TrailBlue from "./trailBlue";
import TrailGreen from "./trailGreen";
import TrailRed from "./trailRed";
import Trash from "./trash";
import Upload from "./upload";
import User from "./user";
import Users from "./users";
import WeatherCloud from "./weatherCloud";
import WeatherCloudy from "./weatherCloudy";
import WeatherFoggy from "./weatherFoggy";
import WeatherHalfsunny from "./weatherHalfsunny";
import WeatherRain from "./weatherRain";
import WeatherSleet from "./weatherSleet";
import WeatherSnowing from "./weatherSnowing";
import WeatherStorm from "./weatherStorm";
import WeatherSunny from "./weatherSunny";
import Wind from "./wind";
import X from "./x";
import XCircle from "./xCircle";

const Icons = {
  AlertCircle: <AlertCircle />,
  AlertTriangle: <AlertTriangle />,
  Arrival: <Arrival />,
  ArrowDown: <ArrowDown />,
  ArrowDownLeft: <ArrowDownLeft />,
  ArrowDownRight: <ArrowDownRight />,
  ArrowLeft: <ArrowLeft />,
  ArrowRight: <ArrowRight />,
  ArrowUp: <ArrowUp />,
  ArrowUpLeft: <ArrowUpLeft />,
  ArrowUpRight: <ArrowUpRight />,
  Bed2: <Bed2 />,
  Beds: <Beds />,
  Bell2: <Bell2 />,
  Bike: <Bike />,
  BoardingPass: <BoardingPass />,
  BootSki: <BootSki />,
  Buildings: <Buildings />,
  Calendar: <Calendar />,
  Carpet: <Carpet />,
  Center: <Center />,
  Chairlift: <Chairlift />,
  Check: <Check />,
  CheckCircle: <CheckCircle />,
  ChevronDown: <ChevronDown />,
  ChevronLeft: <ChevronLeft />,
  ChevronRight: <ChevronRight />,
  ChevronsDown: <ChevronsDown />,
  ChevronsUp: <ChevronsUp />,
  ChevronUp: <ChevronUp />,
  Clock: <Clock />,
  Cloud: <Cloud />,
  CloudRain: <CloudRain />,
  Compass: <Compass />,
  CovidSecurity: <CovidSecurity />,
  CreditCard: <CreditCard />,
  Cross: <Cross />,
  DownloadButton: <DownloadButton />,
  DragHorizontal: <DragHorizontal />,
  Edit: <Edit />,
  Email: <Email />,
  ExitButton: <ExitButton />,
  Eye: <Eye />,
  EyeOff: <EyeOff />,
  File: <File />,
  FileText: <FileText />,
  Filter: <Filter />,
  Flag: <Flag />,
  Flights: <Flights />,
  FullScreen: <FullScreen />,
  Gondola: <Gondola />,
  Grid: <Grid />,
  GuestCard: <GuestCard />,
  GuestCardTwo: <GuestCardTwo />,
  Helmet: <Helmet />,
  Image: <Image />,
  Info: <Info />,
  Lift: <Lift />,
  List: <List />,
  Lock: <Lock />,
  Login: <Login />,
  Logout: <Logout />,
  Mailbox: <Mailbox />,
  MapPin: <MapPin />,
  Menu: <Menu />,
  Minus: <Minus />,
  MinusCircle: <MinusCircle />,
  Package: <Package />,
  Parking: <Parking />,
  Phone: <Phone />,
  Pin: <Pin />,
  PlayButton: <PlayButton />,
  Plus: <Plus />,
  PlusCircle: <PlusCircle />,
  Poles: <Poles />,
  QrScanner: <QrScanner />,
  Search: <Search />,
  Settings: <Settings />,
  ShoppingCart: <ShoppingCart />,
  Skiing: <Skiing />,
  SkisOnly: <SkisOnly />,
  Snow: <Snow />,
  Snowboard: <Snowboard />,
  Snowflake: <Snowflake />,
  Sort: <Sort />,
  Star: <Star />,
  Sun: <Sun />,
  SunMenu: <SunMenu />,
  Toaster: <Toaster />,
  Tow: <Tow />,
  Town: <Town />,
  TrailBlack: <TrailBlack />,
  TrailBlack2: <TrailBlack2 />,
  TrailBlue: <TrailBlue />,
  TrailGreen: <TrailGreen />,
  TrailRed: <TrailRed />,
  Trash: <Trash />,
  Upload: <Upload />,
  User: <User />,
  Users: <Users />,
  WeatherCloud: <WeatherCloud />,
  WeatherCloudy: <WeatherCloudy />,
  WeatherFoggy: <WeatherFoggy />,
  WeatherHalfsunny: <WeatherHalfsunny />,
  WeatherRain: <WeatherRain />,
  WeatherSleet: <WeatherSleet />,
  WeatherSnowing: <WeatherSnowing />,
  WeatherStorm: <WeatherStorm />,
  WeatherSunny: <WeatherSunny />,
  Wind: <Wind />,
  X: <X />,
  XCircle: <XCircle />,
};

const Icon = ({ icon, size, className, color, onClick }: IconProps) => {
  const IconComponent: any = Icons[icon];
  const allStyles = `${color ? styles.iconColor : ""} ${styles[size]} ${
    className ? className : ""
  }`;

  const iconColor = { "--iconColor": color } as React.CSSProperties;

  if (!IconComponent) return null;
  return (
    <span className={allStyles} onClick={onClick} style={iconColor}>
      {IconComponent}
    </span>
  );
};

export { Icons };
export default Icon;
