import * as React from "react";

const Email = (props) => (
  <svg
    width={24}
    height={25}
    viewBox={"0 0 24 25"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 6.5a2 2 0 012-2h18a2 2 0 012 2v12a2 2 0 01-2 2H3a2 2 0 01-2-2v-12z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M2.28 5.78a.75.75 0 01.53-1.28h18.38a.75.75 0 01.53 1.28L14.12 13.38a3 3 0 01-4.242 0L2.28 5.78z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);

export default Email;
