import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.111 22v-2.4M17.556 22v-2.4M23 14.8h-2m0 0v-3.2c0-1.768-1.726-3.2-3.444-3.2H5.11C3.392 8.4 2 9.832 2 11.6V18c0 .883.697 1.6 1.556 1.6H19.11C19.97 19.6 21 18.883 21 18v-3.2ZM17.361 3.6c0-.88-1.583-1.6-5.861-1.6s-6 .72-6 1.6C5.5 4.48 7 5.16 7 5.16V8.4h9V5.16s1.361-.75 1.361-1.56Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
