import * as React from "react";

const BoardingPass = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.439 8H4M8.83 12H4M20 12h-3M20 8h-3M20 16h-3M14 9v1M14 14v1M14 4.52C14 3.128 14.948 2 16.118 2h4.764C22.052 2 23 3.128 23 4.52v14.96c0 1.392-.948 2.52-2.118 2.52h-4.764C14.948 22 14 20.872 14 19.48M14 19.48c0 1.392-.97 2.52-2.167 2.52H3.167C1.97 22 1 20.872 1 19.48V4.52C1 3.128 1.97 2 3.167 2h8.666C13.03 2 14 3.128 14 4.52"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default BoardingPass;
