import * as React from "react";

const Gondola = (props) => (
  <svg
    {...props}
    viewBox="0 0 30 30"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.88672 5L20.0004 3.75"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 10.625L15 5.625"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.875 12.625C1.875 11.5204 2.77043 10.625 3.875 10.625H26.125C27.2296 10.625 28.125 11.5204 28.125 12.625V16.125C28.125 17.2296 27.2296 18.125 26.125 18.125H3.875C2.77043 18.125 1.875 17.2296 1.875 16.125V12.625Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.875 12.625C1.875 11.5204 2.77043 10.625 3.875 10.625H26.125C27.2296 10.625 28.125 11.5204 28.125 12.625V23.625C28.125 24.7296 27.2296 25.625 26.125 25.625H3.875C2.77043 25.625 1.875 24.7296 1.875 23.625V12.625Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Gondola;
