import * as React from "react";

const Snow = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 1v22M2.473 6.5l19.052 11M2.473 17.5l19.052-11M9 3l3 2.5L15 3M9 21l3-2.5 3 2.5M2.703 10.098L6.368 8.75l-.665-3.848M18.293 19.098l-.665-3.848 3.665-1.348M5.703 19.098l.665-3.848-3.665-1.348M21.293 10.098L17.628 8.75l.665-3.848"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Snow;
