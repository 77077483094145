import * as React from "react";

const File = (props) => (
  <svg
    width={16}
    height={16}
    viewBox={"0 0 16 16"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.667 1.333H4c-.736 0-1.333.597-1.333 1.334v10.666c0 .737.597 1.334 1.333 1.334h8c.737 0 1.334-.597 1.334-1.333V6M8.667 1.333L13.334 6M8.667 1.333V6h4.667"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default File;
