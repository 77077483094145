import * as React from "react";

const Snowboard = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.457 21.457a4.28 4.28 0 0 1 .427-6.427l2.647-2.024a39.999 39.999 0 0 0 7.475-7.475l2.024-2.647a4.28 4.28 0 0 1 6.427-.427v0a4.28 4.28 0 0 1-.303 6.33l-3.815 3.147a39.996 39.996 0 0 0-5.405 5.405l-3.147 3.815a4.28 4.28 0 0 1-6.33.303v0Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.946 9.135.717.716M9.892 13.188l.717.716"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Snowboard;
