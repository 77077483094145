import * as React from "react";

const CheckCircle = (props) => (
  <svg
    width={30}
    height={30}
    viewBox={"0 0 30 30"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.25 15.088v1.162a12.5 12.5 0 11-7.412-11.425"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 5L13.75 18.75 10 15"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckCircle;
