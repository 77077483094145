// extracted by mini-css-extract-plugin
export var wordWrap = "styles-module--wordWrap--3KSOQ";
export var h1 = "styles-module--h1--SbZjI";
export var h2 = "styles-module--h2--1L2cn";
export var h3Heavy = "styles-module--h3-heavy--38mNJ";
export var h3 = "styles-module--h3----sec";
export var h4 = "styles-module--h4--2d4P8";
export var h4Heavy = "styles-module--h4-heavy--viA7M";
export var h4Medium = "styles-module--h4-medium--unGgl";
export var pHeavy = "styles-module--p-heavy--1FGlP";
export var pMedium = "styles-module--p-medium--256oV";
export var pLine = "styles-module--p-line--3blMh";
export var h900Medium = "styles-module--h900-medium--2FCZ-";
export var h900Heavy = "styles-module--h900-heavy--3mJcA";
export var h800Medium = "styles-module--h800-medium--27fu_";
export var h800Heavy = "styles-module--h800-heavy--2TfkP";
export var h700Medium = "styles-module--h700-medium--22jwA";
export var h700Heavy = "styles-module--h700-heavy--jelYR";
export var h600Medium = "styles-module--h600-medium--22yn6";
export var h600Heavy = "styles-module--h600-heavy--383yY";
export var h500Medium = "styles-module--h500-medium--TXx5Z";
export var h500Heavy = "styles-module--h500-heavy--2UcHm";
export var h400Medium = "styles-module--h400-medium--1sqyt";
export var h400Heavy = "styles-module--h400-heavy--3kVPW";
export var h300Medium = "styles-module--h300-medium--2v4Yq";
export var h300Heavy = "styles-module--h300-heavy--1xyVK";
export var h200Medium = "styles-module--h200-medium--3QsjN";
export var h200Heavy = "styles-module--h200-heavy--2GPVR";
export var h100Heavy = "styles-module--h100-heavy--2_X_O";
export var h400Light = "styles-module--h400-light--1ViGY";