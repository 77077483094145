import * as React from "react";

const DownloadButton = (props) => (
  <svg
    width={24}
    height={25}
    viewBox={"0 0 24 25"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 17.5v3a2 2 0 002 2h14a2 2 0 002-2v-3M8 12.5l4 4 4-4M12 2.5v14"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadButton;
