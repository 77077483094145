import * as React from "react";

const Users = (props) => (
  <svg
    width={18}
    height={16}
    viewBox={"0 0 18 16"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.024 14.267c1.878 0 2.451-.286 2.626-.455.07-.067.087-.169.108-.264l.416-1.952a2 2 0 00-1.956-2.418h-.861m-2.644-2.321a2.667 2.667 0 00-.046-5.334m-4 12.989c1.605 0 2.86-.297 3.607-.534.49-.156.826-.582.934-1.086l.277-1.296a2 2 0 00-1.956-2.417H3.805a2 2 0 00-1.956 2.417l.277 1.296c.108.504.443.93.934 1.086.746.237 2.002.534 3.607.534zM9.334 4.178a2.667 2.667 0 11-5.334 0 2.667 2.667 0 015.334 0z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Users;
