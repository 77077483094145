import React from "react";
import * as styles from "./styles.module.scss";
import DotsAnimation from "../../animations/dotsAnimation";

const Button = (props) => {
  const {
    children,
    onClick,
    onFocus,
    className,
    contentClassName = "",
    disabled = false,
    loading = false,
    contentComponent = "span",
    style = {},
    ariaLabel = "",
  } = props;
  const CustomTag: any = `${contentComponent}`;
  return (
    <button
      type="button"
      className={`${styles.reset} ${className}`}
      onClick={(e) => onClick && onClick(e)}
      onMouseDown={(e) => e.preventDefault()}
      disabled={disabled || loading}
      onFocus={() => onFocus && onFocus()}
      style={style}
      aria-label={ariaLabel}
    >
      {loading && <DotsAnimation className={styles.loader} />}
      <CustomTag
        style={{ display: "flex" }}
        className={`${contentClassName} ${loading && styles.hideContent}`}
      >
        {children}
      </CustomTag>
    </button>
  );
};

export default Button;
