import * as React from "react";

const WeatherCloud = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.742 12.48h1.26c2.76 0 4.998 2.02 4.474 4.639-.308 1.541-1.714 3.143-4.475 3.143H9.004c-4.157.003-7.623-1.148-7.975-5.178-.352-4.03 2.528-7.65 6.626-8.33 4.098-.679 8.048 1.81 9.087 5.726zm0 0v1.458m1.561-6.247h.946c2.074 0 3.751 1.746 3.751 3.142m-4.697-3.142c-.78-2.812-3.748-4.599-6.827-4.111m6.827 4.111v1.047"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WeatherCloud;
