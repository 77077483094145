import * as React from "react";

const Skiing = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.628 2.25c-.492 0-.964.192-1.312.533a1.8 1.8 0 00-.544 1.285c0 .482.196.945.544 1.286.348.34.82.532 1.312.532.492 0 .964-.191 1.312-.532a1.8 1.8 0 00.543-1.286 1.8 1.8 0 00-.543-1.285 1.875 1.875 0 00-1.312-.533v0z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
    />
    <path
      d="M2 12.825l16.272 9.143s2.107.922 3.228-.572"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.008 7.008L4.273 2.264m15.338 10.44L15.917 9.93l-.02-3.624A1.244 1.244 0 0014.42 5.1c-.871.166-1.977.542-2.876 1.386a35.493 35.493 0 00-1.586 1.61 1.944 1.944 0 00.367 2.967l3.397 2.224a.9.9 0 01-.042 1.538l-3.862 2.24 9.792-4.361z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Skiing;
