import * as React from "react";

const Pin = (props) => (
  <svg
    width={24}
    height={25}
    viewBox={"0 0 24 25"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 11.283c0 4.26-6.218 9.709-8.848 11.823a1.827 1.827 0 01-2.304 0C8.218 20.992 2 15.543 2 11.283 2 5.88 6.477 1.5 12 1.5s10 4.38 10 9.783z"
      stroke="currentColor"
      strokeWidth={2}
    />
    <circle cx={12} cy={11} r={3} stroke="currentColor" strokeWidth={2} />
  </svg>
);

export default Pin;
