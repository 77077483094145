import * as React from "react";

const AlertTriangle = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.29 2.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 2.86a2 2 0 00-3.42 0z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.5v4"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g
      filter="url(#prefix__filter0_d_7218:329315)"
      transform="rotate(-180 12 16.5)"
    >
      <circle
        cx={12}
        cy={16.5}
        r={0.5}
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="prefix__filter0_d_7218:329315"
        x={6.75}
        y={15.25}
        width={10.5}
        height={10.5}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7218:329315"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7218:329315"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default AlertTriangle;
