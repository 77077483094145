import * as React from "react";

const Helmet = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.249 14.25-.408 1.562a2.992 2.992 0 0 1-1.558 1.921l-.01.005a2.479 2.479 0 0 1-2.781-.39l-2.243-2.053V9.5c0-4.12 3.716-7.25 7-7.25h5.75S20 2 20.249 7.75c0 0 1.5.148 1.5 1.75v3.507s-.131 1.243-1.5 1.243h-5v4h-2.5l-1-4h-2.5ZM9.343 14.25H7.5m10-6.5h2.749M6.999 18.5S7.5 22 10.5 22c2.5 0 2.999-3 2.999-3"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Helmet;
