import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m8.75 4 7.75 8.75-7.75 8.75L1 12.75 8.75 4Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M15.25 4 23 12.75l-7.75 8.75-7.75-8.75L15.25 4Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
