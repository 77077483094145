import * as React from "react";

const Bell2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12 6.4c4.4 0 8.1 3.4 9 8.1.1.6 0 1.9-1.7 1.9H4.8c-1.5 0-1.8-1.4-1.7-1.9.8-4.7 4.5-8.1 8.9-8.1zM21.2 16.4H12h0-9.2c-.7 0-1.7.8-1.7 2s1 2 1.7 2h18.4c.7 0 1.7-.8 1.7-2s-1-2-1.7-2zM12 6.4V3.5M9.7 3.5h4.6"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
    />
  </svg>
);

export default Bell2;
