import * as React from "react";

const TrailRed = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.656 21a1 1 0 0 1-.885-1.466l8.344-15.853a1 1 0 0 1 1.77 0l8.344 15.853A1 1 0 0 1 20.344 21H3.656Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrailRed;
