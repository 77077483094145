import * as React from "react";

const Tow = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox={"0 0 24 24"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 5.044v14M19.965 2.91 4.035 7.179"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M7.936 18.284a.248.248 0 0 1 .303-.176l7.25 1.943c.131.035.21.171.174.303v0a.248.248 0 0 1-.303.175L8.11 18.587a.248.248 0 0 1-.175-.303v0Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Tow;
