import * as React from "react";

const Poles = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4.864 17.334 1.762 1.778"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.785 8.296a.516.516 0 0 1-.734 0l-1.469-1.482a.527.527 0 0 1 0-.74L18.52 3.11a1.548 1.548 0 0 1 2.203 0 1.581 1.581 0 0 1 0 2.223l-2.937 2.963Z"
      stroke="currentColor"
      strokeWidth={1.8}
    />
    <path
      d="M15.436 8.445 3.102 20.89M17.375 19.111l1.762-1.777"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.418 6.075a.527.527 0 0 1 0 .74L6.949 8.298a.516.516 0 0 1-.734 0L3.278 5.334a1.581 1.581 0 0 1 0-2.222 1.548 1.548 0 0 1 2.203 0l2.937 2.963Z"
      stroke="currentColor"
      strokeWidth={1.8}
    />
    <path
      d="M8.564 8.445 20.9 20.89"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Poles;
