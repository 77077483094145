import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M16.8 8.2v1.5m0-1.5H18c.7 0 1.3.1 1.9.4.6.3 1.2.6 1.6 1.1.5.5.8 1 1.1 1.6.3.6.4 1.3.4 1.9 0 2.8-2.2 3-5 3H9c-4.2 0-7.6-1.2-8-5.3-.2-2 .3-4.1 1.6-5.7 1.2-1.6 3-2.7 5.1-3.1 2-.3 4.1.1 5.8 1.2s2.8 2.9 3.3 4.9z"
      fill={"none"}
      stroke={"currentColor"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <circle
      className="st1"
      cx={4.5}
      cy={19.4}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={7.7}
      cy={19.4}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={10.9}
      cy={19.4}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={14.1}
      cy={19.4}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={17.3}
      cy={19.4}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={20.5}
      cy={19.4}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={6.1}
      cy={21.9}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={9.3}
      cy={21.9}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={12.5}
      cy={21.9}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={15.7}
      cy={21.9}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
    <circle
      className="st1"
      cx={18.9}
      cy={21.9}
      r={0.5}
      stroke={"currentColor"}
      strokeWidth={1}
    />
  </svg>
);

export default SvgComponent;
