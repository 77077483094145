import * as React from "react";

const WeatherFoggy = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox={"0 0 24 24"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 22h15M2.5 18.5h3M21 22h.5M9 18.5h12.5M16.802 7.232v1.5m0-1.5h1.25a5 5 0 0 1 5 5c0 2.76-2.24 3-5 3h-9c-4.16 0-7.63-1.18-8-5.32a8 8 0 0 1 15.75-2.68Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WeatherFoggy;
