import * as React from "react";

const Carpet = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <g clipPath="url(#c)" fill="currentColor">
          <circle
            cx={5.007}
            cy={13.516}
            r={1.25}
            transform="rotate(-12.789 5.007 13.516)"
          />
          <circle
            cx={9.396}
            cy={12.52}
            r={1.25}
            transform="rotate(-12.789 9.396 12.52)"
          />
          <circle
            cx={13.784}
            cy={11.524}
            r={1.25}
            transform="rotate(-12.789 13.784 11.524)"
          />
          <circle
            cx={18.172}
            cy={10.528}
            r={1.25}
            transform="rotate(-12.789 18.172 10.528)"
          />
        </g>
        <path
          d="M18.505 6.813 3.88 10.069c-1.906.432-3.09 2.374-2.644 4.338.446 1.963 2.352 3.204 4.258 2.772l14.624-3.256c1.905-.433 3.089-2.375 2.643-4.338-.445-1.964-2.352-3.205-4.257-2.772Z"
          stroke="currentColor"
          strokeWidth={1.8}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <clipPath id="b">
        <path
          fill="#fff"
          transform="rotate(-2.789 135.252 9)"
          d="M0 0h24v12H0z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          fill="#fff"
          transform="rotate(-12.789 57.855 -9.38)"
          d="M0 0h16.806v2.626H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Carpet;
