import * as React from "react";

const User = (props) => (
  <svg
    width={30}
    height={30}
    viewBox={"0 0 30 30"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.953 26.045c-1.22.456-3.612 1.142-6.953 1.142-3.357 0-5.757-.693-6.97-1.149-.594-.223-1.019-.723-1.223-1.323l-1.193-3.513c-.666-1.96.806-3.99 2.875-3.965l6.511.076 6.444-.076a3 3 0 012.889 3.927l-1.147 3.528c-.2.614-.629 1.127-1.233 1.353zM15 12.813a5 5 0 100-10 5 5 0 000 10z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default User;
