import * as React from "react";

const Minus = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox={"0 0 24 24"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 12h16"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Minus;
