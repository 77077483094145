import * as React from "react";

const SkisOnly = (props) => (
  <svg
    width={24}
    height={24}
    viewBox={"0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 7.5-2.9-5c-.6-1-1.8-1.3-2.8-.7-1 .5-1.3 1.8-.8 2.7l4.1 7M12 15.5l2.5 4.2 1.4 2.4c.2.3.6.4.9.2l2.4-1.4c.1-.1.3-.2.3-.4s0-.3-.1-.5l-5.1-8.6"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
    />
    <path
      d="M17.7 1.8c-1-.6-2.2-.2-2.8.7L4.6 20.2c-.1.1-.1.3-.1.5s.1.3.3.4l2.4 1.4c.3.2.7.1.9-.2l1.4-2.4 8.9-15.3c.6-1 .3-2.3-.7-2.8"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
    />
  </svg>
);

export default SkisOnly;
