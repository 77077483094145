import * as React from "react";

const Filter = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox={"0 0 24 24"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.5 12h-10"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
    />
    <circle
      r={2.6}
      transform="matrix(-1 0 0 1 7 12)"
      stroke="currentColor"
      strokeWidth={1.8}
    />
    <path
      d="M13.5 4h-10"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
    />
    <circle
      r={2.6}
      transform="matrix(-1 0 0 1 17 4)"
      stroke="currentColor"
      strokeWidth={1.8}
    />
    <path
      d="M13.5 20h-10"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
    />
    <circle
      r={2.6}
      transform="matrix(-1 0 0 1 17 20)"
      stroke="currentColor"
      strokeWidth={1.8}
    />
  </svg>
);

export default Filter;
